<template>
    <app-wrapper>
        <template v-slot:child>
            <JournalHeader />

            <slot name="child"> </slot>
        </template>
    </app-wrapper>
</template>

<script>
import AppWrapper from '@/layout/AppWrapper'
import JournalHeader from './JournalHeader'

export default {
    name: 'JournalWrapper',
    components: { AppWrapper, JournalHeader },
}
</script>

<style scoped></style>