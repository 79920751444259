<template>
    <journal-wrapper>
        <template v-slot:child>
            <div class="w-80 center mt4">
                <div class="pad1632 mobPad flex" style="justify-content: flex-end; gap: 12px">
                    <button class="btn2">Save as Draft</button>
                    <button>Submit</button>
                </div>
                <div>
                    <div class="flex justify-between w-100">
                        <div class="flex gap8 flex-column w-40 pt4">
                            <div class="b">Date</div>
                            <div class="w-100"><input class="journalDate w-100" type="date" /></div>
                        </div>
                        <div class="flex gap8 flex-column w-40 pt4">
                            <div class="b">Transaction ID</div>
                            <div class="w-100"><input class="journalDate w-100" type="text" /></div>
                        </div>
                    </div>
                    <div class="pad16">
                        <textarea placeholder="Enter Description here" class="w-100 jTextArea"></textarea>
                    </div>
                    <div>
                        <div class="flex justify-between pad1016 tbSub">
                            <div class="w-30 tl b mobSpace">Description</div>
                            <div class="w-30 tl b ">Account</div>
                            <div class="w-30 tr b ">Debits</div>
                            <div class="w-30 tr b">Credits</div>
                        </div>
                    </div>
                    <div>
                        <div class="flex justify-between pad1016 tbSub">
                            <div class="w-30 tl f500 mobSpace">Write a description</div>
                            <div class="w-30 tl f500">Uncategorized Expenses</div>
                            <div class="w-30 tr f500">0.00</div>
                            <div class="w-30 tr f500">0.00</div>
                        </div>
                    </div>
                    <div>
                        <div class="flex justify-between pad1016 tbSub">
                            <div class="w-30 tl f500 mobSpace">Write a description</div>
                            <div class="w-30 tl f500">Uncategorized Expenses</div>
                            <div class="w-30 tr f500">0.00</div>
                            <div class="w-30 tr f500">0.00</div>
                        </div>
                    </div>
                    <div class="b pl3 pv3" style="color: #132c8c">+ Add new line</div>
                    <div class="flex pl3 pr3" style="justify-content: flex-end;">
                        <div class="w-50 flex">
                            <div class="w-100">
                                <div class="box-border-bottom w-70 mbJLine" style=" float: right"></div>
                                <div style="clear: both"></div>
                                <div class="flex justify-between pad16">
                                    <div class="w-50 tr b"  style="white-space:nowrap">Total Debits</div>
                                    <div class="b">0.00</div>
                                </div>
                                <div class="flex justify-between pad16">
                                    <div class="w-50 tr b" style="white-space:nowrap">Total Credits</div>
                                    <div class="b">0.00</div>
                                </div>
                                <div class="w-70 mbJLine" style="border: 1px solid black; float: right"></div>
                                <div style="clear: both"></div>
                                <div class="flex justify-between pad16">
                                    <div class="w-50 tr b" style="color: #a94442; font-size: 16px">Difference</div>
                                    <div class="b" style="color: #a94442; font-size: 16px">0.00</div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </journal-wrapper>
</template>
<script>
import JournalWrapper from './widgets/JournalWrapper'
export default {
    name: 'EditJournal',
    components: { JournalWrapper },
    setup() {
        return {}
    },
}
</script>
<style>
.pad1632 {
    padding: 16px 32px;
}
.journalDate {
    padding: 14px 12px 11px 12px;
    border-radius: 4px;
    border: 1px solid #e3e8ee;
}
.pad16 {
    padding: 16px 0;
}
.jTextArea {
    border-radius: 4px;
    border: 1px solid #e3e8ee;
    height: 93px;
    padding: 10px 16px;
    color: #989db1;
    font-size: 14px;
    font-weight: 500;
}
.pad1016 {
    padding: 10px 16px;
}
.tbSub {
    font-size: 14px;
}
.f500 {
    font-weight: 500;
    color: #1a2035;
}
.gap8{
    gap: 8px;
}
@media screen and (max-width: 500px){
    .mobPad{
        padding: 16px 0px;
    }
    .mobSpace{
        padding-right: 20px;
    }
}
@media screen and (max-width: 1090px){
    .mbJLine{
        width: 80%;
    }
}
@media screen and (max-width: 650px){
    .mbJLine{
        width: 100%;
    }
     .mobPad{
        padding: 16px 0px;
    }
}
</style>
