<template>
     <div>
        <div class="w-80 center pv4-l pv3 mini-spacing">
            <p class="pb2 f3 b">
                Journal Entry
                <strong
                    ><font-awesome-icon
                        icon="fa-solid fa-question-circle fa-2xs"
                        class="text-muted clickTooltipIcon"
                        @click="toggleTooltip = true"
                        style="cursor: pointer"
                /></strong>
            </p>

            <div class="dashboard-inquiry-modal" v-if="toggleTooltip">
                Use this to track money you or others have invested into the business. For example, when you first start a
                business you usually invest start-up money into it.
            </div>
        </div>
        <!-- chart of accounts -->

        <!-- menu -->
        <div class="w-80 center">
            <div class="flex scrollmenu" style="gap: 24px">
                <router-link
                    active-class="active-menu"
                    :class="{ 'active-menu': $route.fullPath.startsWith('/journal-entry') }"
                    :to="{ name: 'ManageAssets' }"
                >
                    <div class="mr3">Edit</div>
                </router-link>
                <!-- <router-link active-class="active-menu" :to="{ name: 'OpeningBalance' }">
                    <div class="mr3">Notes</div>
                </router-link> -->
            </div>
        </div>
    </div>
</template>
<script>
import { ref, onMounted } from 'vue'
import AppWrapper from '@/layout/AppWrapper'
export default {
    name: 'JournalHeader',
    setup() {
        const toggleTooltip = ref(false)
        const closeTooltip = () => {
            toggleTooltip.value = false
        }
        window.addEventListener('click', (event) => {
            const tooltipIcon = document.querySelector('.clickTooltipIcon')

            if (!(event.target.closest('.clickTooltipIcon') || event.target === tooltipIcon) && toggleTooltip.value === true) {
                closeTooltip()
            }
        })
        return {
            toggleTooltip,
            closeTooltip,
        }
    },
}
</script>